import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import TopHat from "../components/tophat"
import Footer from "../components/footer"
import Content from "../components/content"
import ShareLinks from "../components/ShareLinks"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const BlogPost = ({data, pageContext, location}) => {
    const post = data.graphAPI.blogPost
    console.log(data)
    let baseurl = `https://hayswcid.netlify.app`
    return (
        <Layout>
            <SEO title={post.title} meta={[
                { name: `og:url`,
                content: `${baseurl}${location.pathname}` },
                { name: `og:type`,
                content: `article` },
                { name: `og:title`,
                content: `${post.title}` },
                { name: `og:description`,
                content: `${post.description}` },
                { name: `og:image`,
                content: `${baseurl}${post.heroImage.documentFile.childImageSharp.resize.src}` },
            ]}/>
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '0'}}>Hays WCID {pageContext.district}</small><br />
                        <h1 className="title is-size-1">{post.title}</h1>
                        <small style={{display: 'block', marginTop: '-1rem'}}>{moment(post.publishedAt).format("MMM D, YYYY")}</small>
                        <div class="dropdown is-hoverable" style={{marginTop: '2rem'}}>
                        <div class="dropdown-trigger">
                            <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                            <span>Share</span>
                            <span className="icon is-small">
                                <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                            <div class="dropdown-content">
                            <ShareLinks url={`${baseurl}${location.pathname}`} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <Content content={post.content} district={pageContext.district} />
                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: ID!) {
        graphAPI {
            blogPost(id: $id) {
                id
                title
                description
                heroImage {
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            resize(width: 1024){
                                src
                            }
                        }
                    }
                }
                publishedAt
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        Body
                        minHeight
                        id
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        id
                        Placement
                        Width
                        Image {
                            url
                            updated_at
                            documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        id
                        style
                        image {
                          updated_at
                          url
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 512){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                        url
                        linkbody: body
                        aspectRatio
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        id
                        splitbody: body
                        image {
                          updated_at
                          url
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        id
                        images {
                          url
                          updated_at
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                    }

                    ... on GRAPHAPI_ComponentPageLinkList {
                        id
                        link {
                            id
                            title
                            style
                            type
                            url
                            document {
                                id
                                url
                                updated_at
                                documentFile {
                                    publicURL
                                    prettySize
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
`


